import { ApolloError } from "@apollo/client";
import {
  Alert,
  Button,
  Flexbox,
  Label,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { CrossCircle } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { useEffect, useRef, useState } from "react";

type TApolloErrorViewerProps = {
  error?: ApolloError | ApolloError[];
  label?: string;
  customErrorMessage?: string;
  autoDismissable?: boolean;
  timeOutMilli?: number;
  onClose?: () => void;
};

export const ApolloErrorViewer = (props: TApolloErrorViewerProps) => {
  const { error, label, customErrorMessage, onClose } = props;
  const autoDismissableTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const errors = Array.isArray(error) ? error : [error];
  const [showError, setShowError] = useState(false);

  const graphQLErrorMessages = new Set(
    errors
      .flatMap(e => e?.graphQLErrors?.map(graphqlError => graphqlError.message))
      .filter(Boolean)
  );

  const netWorkErrorMessages = new Set(
    errors.flatMap(e => e?.networkError?.message).filter(Boolean)
  );

  const errorMessages = new Set(errors.flatMap(e => e?.message).filter(Boolean));

  useEffect(() => {
    setShowError(true);
    if (autoDismissableTimeout.current) {
      clearTimeout(autoDismissableTimeout.current);
    }

    autoDismissableTimeout.current = props.autoDismissable
      ? setTimeout(() => {
          props.onClose?.();
          setShowError(false);
        }, props.timeOutMilli)
      : null;

    // cleanup function
    return () => {
      if (autoDismissableTimeout.current !== null) {
        clearTimeout(autoDismissableTimeout.current);
      }
    };
  }, [props]);

  if (!showError) {
    return <></>;
  }

  return (
    <Alert
      size={Sizes.Small}
      label={label}
      sentiment={Sentiments.Negative}
      solidBgColor
      iconVisibility={false}>
      <Flexbox justifyContent="center" alignItems="center" gap="8px">
        <ul>
          {customErrorMessage && (
            <li>
              <p style={{ padding: "0 30px" }}>{customErrorMessage}</p>
            </li>
          )}

          {[...graphQLErrorMessages].map(msg => (
            <li key={msg}>
              <Label>{msg}</Label>
            </li>
          ))}

          {[...netWorkErrorMessages].map(msg => (
            <li key={msg}>
              <Label>`[Network error]: ${msg}`</Label>
            </li>
          ))}

          {[...errorMessages].map(msg => (
            <li key={msg}>
              <Label>${msg}</Label>
            </li>
          ))}
        </ul>
        <Button
          size={Sizes.ExtraSmall}
          icon={<CrossCircle />}
          iconOnly
          variant={Variants.Transparent}
          onClick={() => {
            onClose?.();
            setShowError(false);
          }}
        />
      </Flexbox>
    </Alert>
  );
};
